@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-slider {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.h-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  height: 85vh;
}

.h-image-container img {
  max-width: 85vw;
  max-height: 100vh;
  object-fit: contain;
}

.h-image-container > div {
  position: absolute;

  transition: opacity 0.7s ease-in-out;
  opacity: 0;
}

.h-image-container > div.visible {
  opacity: 1;
}

@media (max-width: 600px) {
  .h-image-container {
    height: 70vh;
  }
}
