.c-content .c-title{
    text-align: center;
    margin: 10px;
    padding-bottom: 30px;
    font-size: 1.3em;
}
.c-content{

    padding-left:15%;
    padding-right: 15%;
    align-content: center;
    position: relative;
    margin:10px;

}
.c-content .c-container{
    display: flex;
    flex-direction: row;
}
.text-icons{
    width: 70%;
}
.c-content .text{
    padding:4%;
    padding-left: 21%;
}
.c-content .image{
    padding-left:0%;
    height: 400px;
}
.c-content .image img{
    object-fit: contain;

    height: 100%;
}
.c-content .info{
    padding-top: 40px;
    text-align: center;
    font-size: 1.3em;
}
.insta{
    padding-right: 20%;
    font-size: 2em;
}
.email{
    font-size: 2em;
}
.emailhandle{
    color: #969696;
}
.emailhandle:hover{
    color:black;
    transition: 0.3s;
}
.instahandle{
    color: #969696;
    transition: 0.3s;
}
.instahandle:hover{
    color: black;
}

@media (max-width: 600px) {
    .c-content{
        padding: 0;
    }
    .c-container{
        flex-direction: column-reverse !important;
        align-items: center;
    }
    .text-icons{
        width: 100%;
    }
    .text{
        text-align: center;
        padding: 4% !important;
    }
    .image{
        padding-left:0px !important;
    }
    .image img {
        width: 100%;
    }

}