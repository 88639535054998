.Gallery {
}
@keyframes fadeUp {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100;
  }
}

.Gallery .container {
}
.gal-content {
}
.noPost {
  position: relative;
  padding: 5%;
  text-align: center;
}
.years {
  display: flex;
  flex-direction: column;

  float: left;
  z-index: 19;
  position: fixed;
  margin-left: 20px;
  margin-top: 20px;
}

.year {
  font-size: 1.1em;
  margin: 15px;
  transition: 0.3s ease;
}
.year:hover {
  opacity: 0.3;
}
.clicked_year {
  font-weight: bold;
  font-size: 1.2em;
}

.Gallery .heading {
  padding: 10px;

  text-align: center;
  position: sticky;
  font-size: 1.3em;
  text-decoration: underline;
}
.content-gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  align-content: center;
  margin-left: 10%;
  width: 80%;
  gap: 20%;

  align-items: center;
}

/* .content-gallery{
    padding-top: 50px;
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    align-items: center;



}   */

.post {
  width: 400px;
  height: auto;
  object-fit: contain;
  margin-bottom: 0%;

  padding-top: 50px;
  position: relative;

  animation: 0.7s ease-in 0s 1 fadeUp;
}

.Gallery .post img {
  /* border-bottom: 3px solid black; */

  opacity: 1;
  width: 100%;
  transition: 0.5s ease;
  /* backface-visibility: hidden; */
}
.Gallery .post video {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 70vh;
  /* border-bottom: 3px solid black; */

  opacity: 1;
  width: 100%;
  transition: 0.5s ease;
}
.imgcontainer:hover img {
  opacity: 0.3;
}
.post .cap {
  padding: 5px;
  font-size: 1.1em;
  font-weight: lighter;
  color: grey;
}
.Bounce {
  display: "absolute";
  margin: "0 auto";
  top: 50%;
  left: 50%;
}

@media (max-width: 600px) {
  /* Changes the container to a vertical stack on mobile */
  .Gallery {
    height: 100vh;
  }
  .content-gallery {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .gal-content {
    width: 100%;
  }

  /* Removes the margin between the boxes */
  .post {
    margin: 0;
    padding: 0;
    padding-top: 30px;
    padding-bottom: 50px;
    width: 300px;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .years {
    display: flex;
    position: relative;
    justify-content: center;
    padding-bottom: 10px;
    flex-direction: row;
    width: 100%;
    margin: 0px;
  }
  .year {
    margin: 15px;
    font-size: 1em;
  }
}
