.a-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  object-fit: contain;
  margin-top: 5%;
  padding-bottom: 50px;
}

.a-title {
  position: relative;
  text-align: center;
  font-size: 1.3em;
}

.a-content .img img {
  object-fit: contain;
  padding-left: 50px;
  height: 100%;
}
.a-content .img {
  height: 400px;
}

.a-content .text {
  width: 50%;
  padding-left: 6%;
}

@media (max-width: 600px) {
  .a-content .text {
    padding-bottom: 30px;
    width: 85%;
  }
  .a-content .img {
    height: 100%;
    padding: 0;
    padding-left: 0px;
    display: flex;
    justify-content: center;
  }
  .a-content .img img {
    width: 100%;
    padding-left: 0px;
    max-width: 90%;
  }
  ul { 
    text-align: left;
  }
}
