@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
:root {
  background-color: #f7f7f7;
}
/* *{ border: 1px solid red; } */
.content {
}
.App {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-color: #f7f7f7;
  margin-left: 30px;
  margin-right: 30px;
}
Main {
}
.instagram {
  float: right;
  font-size: 2em;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  position: absolute;
  top: 50%;
  right: 2%;
}

* {
  text-decoration: none;
  color: black;
  font-family: Gill Sans, Gill Sans MT, Calibri, sans-serif;
  /* font-family: "Times New Roman", Times, serif; */
  margin: 0;
  padding: 0;
  /* height: 100vh; */
  /* width: 100vh; */
}

.title {
  background-color: #f7f7f7;
  position: sticky;
  top: 0;
  text-align: center;

  padding: 20px;
  z-index: 20;

  /* border-style: solid; */
  border-width: 0px 0px 0px 0px;
}
.name {
  font-size: 1.8em;
  /* font-family: 'Great Vibes', cursive;  */
  font-family: Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif;
}

.ham,
.change {
  cursor: pointer;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  position: absolute;
  top: 50%;
  z-index: 20;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  font-size: 1.5em;
}
@media (max-width: 600px) {
  .App {
    margin: 10px 10px 0px;
    /* position: fixed; */
    padding-bottom: 0px;
    /* position: fixed; */
  }
  .nav {
    /* position: fixed; */
    /* overflow: hidden; */
  }
  .loading {
    height: 70vh;
  }
}
