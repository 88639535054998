.navbar{
    position: absolute;
    z-index: 20;


    height: 75vh;
    width: 100vw;
    padding: 0px;
    text-decoration: none;
    right:0;
    overflow: hidden;
}

.navbar li {
    text-align: center;
    font-size: 1.2em;
    list-style: none;
    margin: 30px;

}


.navbar a:hover{
    border-bottom: 2px solid black;

}